import React from 'react';
import WomanImg from '../img/contact/woman.png'
import { motion } from 'framer-motion';
import { transition1 } from '../transitions';
import emailjs from "emailjs-com";

 

function sendEmail(e) {
  e.preventDefault();

emailjs.sendForm('service_rndciel', 'template_iybrryd', e.target, 'x1YFJggpHFEtAQsWR')
  .then((result) => {
      console.log(result.text);
  }, (error) => {
      console.log(error.text);
  });
  e.target.reset()
}


const Contact = () => {
  return <motion.section 
  className='section bg-white'>
    <div className='container mx-auto h-full'>
      <div className='flex flex-col lg:flex-row h-full items-center justify-start pt-36 gap-x-8 text-center lg:text-left'>
        
        <motion.div 
         initial={{ opacity: 0, y: '100%' }} 
         animate={{ opacity: 1, y: 0 }}  
         exit={{ opacity: 0, y: '100%' }}
         transition={transition1}
        className='lg:flex-1 lg:pt-26 px-4'>
          <h1 className='h1'>Contact me</h1>
          <p className='mb-12'><br/>If you need a photoshoot or a website or you just want to ask me a question, fill this form.</p>
          <form onSubmit={sendEmail} className='flex flex-col gap-y-4'>
          <input
              className='outline-none border-b border-b-primary h-[60px] bg-transparent
              font-secondary w-full pl-3 placeholder:text-[#757879]' 
              type='text' placeholder='Subject' name='subject'/>
            <div className='flex gap-x-10'>
              <input
              className='outline-none border-b border-b-primary h-[60px] bg-transparent
              font-secondary w-full pl-3 placeholder:text-[#757879]' 
              type='text' placeholder='Your Name' name='name'/>
              <input
              className='outline-none border-b border-b-primary h-[60px] bg-transparent
              font-secondary w-full pl-3 placeholder:text-[#757879]' 
              type='text' placeholder=' Your email adress' name='email'/>
              
            </div>
            <input
              className='outline-none border-b border-b-primary h-[60px] bg-transparent
              font-secondary w-full pl-3 placeholder:text-[#757879]' 
              type='text' placeholder=' Your message' name='message'/>
              <br/>
              <button type='submit' className='btn mb-[30px] mx-auto lg:mx-0 self-start'>Send it</button>
          </form>
        </motion.div>
        <motion.div
        initial={{ opacity: 0, y: '-100%' }} 
        animate={{ opacity: 1, y: 0 }}  
        exit={{ opacity: 0, y: '-100%' }}
        transition={transition1}
        className='lg:flex-1 mb-[50px]'>
          <img src={WomanImg} alt='' />
        </motion.div>
      </div>
    </div>
  </motion.section>;
};

export default Contact;
