import React from 'react';
import { motion } from 'framer-motion';
import { transition1 } from '../transitions';

const Portfolio = () => {
  return <motion.section 
  initial={{ opacity: 0, y: '100%' }} 
  animate={{ opacity: 1, y: 0 }}  
  exit={{ opacity: 0, y: '100%' }}
  transition={transition1}
  className='section'>
    <div className='container mx-auto h-full relative'>
       <div className='flex flex-col lg:flex-row h-full items-center 
       justify-start gap-x-24 text-center lg:text-left pt-24 
       lg:pt-36 pb-8'>
         <div>
          <h1 className='h1 text-center'>My photo work</h1>
          <p className='mb-12 text-center'>
          <br/>
          Here is my photographer work, the gallery to all my pictures.
          <br/>
          </p>
          <a className='btn mb-[30px] mx-auto lg:mx-0' href='https://instagram.com/louismecky'>
          Have a look
          </a>
         </div>
         <div>
          <h1 className='h1 text-center'>My Devs work</h1>
          <p className='mb-12 text-center'>
          <br/>
          Here is my web developer work, the gallery to all my repositories.
          <br/>
          </p>
          <a className='btn mb-[30px] mx-auto lg:mx-0' href='https://github.com/louismecky'>
          Have a look
          </a>
         </div>
         
       </div>
    </div>

</motion.section>;
};

export default Portfolio;
