import React from 'react';
import Socials from './Socials';
import Logo from '../img/header/logo.png';
import MobileNav from './MobileNav';
import { Link } from 'react-router-dom';

const Header = () => {
  const downloadResume = async () => {
    try {
      const response = await fetch('./resume.pdf');
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'resume.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <header className='fixed w-full px-[30] lg:px-[100px] z-30 h-[100px] lg:h-[140px] flex items-center'>
      <div className='flex flex-col lg:flex-row lg:items-center w-full justify-between'>
        {/*LOGO*/}
        <Link to={'/'} className='ml-[10px] max-w-[200px]'>
          <img src={Logo} alt='' />
        </Link>

        {/*NAV*/}
        <nav className='hidden lg:flex gap-x-12 font-semibold '>
          <Link to={'/'} className='text-primary'>
            Home
          </Link>
          <Link to={'/about'} className='text-primary'>
            About
          </Link>
          <Link to={'/portfolio'} className='text-primary'>
            Portfolio
          </Link>
          <Link to={'/'} className='text-primary' onClick={downloadResume}>
            Resume
          </Link>
          <Link to={'/contact'} className='text-primary'>
            Contact
          </Link>
        </nav>
      </div>

      {/*Socails*/}
      <Socials />

      {/*mobile nav*/}
      <MobileNav />
    </header>
  );
};

export default Header;