import React from 'react';
import {
  FaInstagram,
  FaLinkedin,
  FaGithub,
} from 'react-icons/fa';


const Socials = () => {
  return <div className='hidden xl:flex ml-44'>
    <ul className='flex gap-x-6'>
      <li>
        <a href='https://instagram.com/louismecky' target="_blank" rel="noreferrer">
          <FaInstagram/>
        </a>
      </li>
      <li>
        <a href='https://linkedin.com/in/louis-esteban-mecky-a3317a214/' target="_blank" rel="noreferrer">
          <FaLinkedin/>
        </a>
      </li>
      <li>
        <a href='https://github.com/louismecky' target="_blank" rel="noreferrer">
          <FaGithub/>
        </a>
      </li>
    </ul>
  </div>;
};

export default Socials;
