import React from 'react';
import WomanImg from '../img/about/woman.png'
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { transition1 } from '../transitions';

const About = () => {
  return <motion.section
  initial={{ opacity: 0, y: '100%' }} 
  animate={{ opacity: 1, y: 0 }}  
  exit={{ opacity: 0, y: '100%' }}
  transition={transition1}
  className='section'>
    <div className='container mx-auto h-full relative'>
      <div className='flex flex-col lg:flex-row h-full 
      items-center justify-center gap-x-24 text-center lg:text-left lg:pt-16'>
        <div className='flex-1 max-h-96 lg:max-h-max order-2 lg:order-none overflow-hidden'>
          <img src={WomanImg} alt=''/>
        </div>
        <div className='flex-1 pt-36 pb-14 lg:pt-0 lg:w-auto z-10 flex flex-col justify-center items-center lg:items-start'>
          <h1 className='h1'>
            About me
          </h1>
          <p className='mb-12'>
          <br/>
          As a photographer, my goal is to capture the soul of the French Riviera in my frames. I paint with my lens, transforming each moment into a vibrant narrative of color, texture, and emotion. My passion for the rich cultural tapestry and stunning landscapes of the Côte d'Azur resonates in my work, inviting you to explore the region's allure through my eyes.
          <br/>
          <br/>
          Parallel to my photographic journey, I also navigate the fascinating world of web development. In today's digital age, a compelling online presence is indispensable, and I use my technical expertise to help you establish exactly that. I craft websites that are as visually striking as they are functionally robust, ensuring a seamless user experience that stands the test of time.
          </p>
          <Link to={'/portfolio'} className='btn'>View my work</Link>
        </div>
      </div>
    </div>
  </motion.section>;
};

export default About;
